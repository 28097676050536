/* eslint-disable no-console */
// eslint-disable-next-line no-console

/**
 * For Wiserfunding purposes, we will have 3 'production' builds in Vercel, for different purposes:
 * production  - client-facing, production API @ risk.wiserfunding.com
 * prerelease  - internal, sandbox API for user-acceptance testing @ beta.wiserfunding.com
 * development - internal, development API for basic PRs and monitoring @ alpha.wiserfunding.com
 */
let URL = '';
let WF_ENV = 'local';

// NODE_ENV === 'production' on live AND preview
if (process.env.NODE_ENV === 'production') {
  if (process.env.VERCEL_ENV === 'preview') {
    WF_ENV = 'development';
    URL = `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`;
  }
  if (process.env.VERCEL_ENV === 'production') {
    URL = 'https://risk.wiserfunding.com';
    WF_ENV = 'production';
  }
} else if (
  process.env.NODE_ENV === 'test' &&
  process.env.VERCEL_ENV === 'preview'
) {
  URL = `${process.env.VERCEL_URL}`;
} else if (process.env.CI && process.env.TEST_METHOD === 'playwright') {
  URL = `${process.env.VERCEL_URL}`;
} else {
  URL = 'http://localhost:3000';
}

let API_URL = process.env.NEXT_PUBLIC_WF_API_ROUTE;
let ADMIN_API_URL = process.env.NEXT_PUBLIC_WF_AP_ADMIN_ROUTE;

// for unit tests
if (process.env.NODE_ENV === 'test' && !process.env.VERCEL_ENV) {
  API_URL = 'http://localhost:3000/api';
}

const IS_LIVE_PROD =
  process.env.NODE_ENV === 'production' &&
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';

const SHOW_DEBUG =
  process.env.NEXT_PUBLIC_GIT_BRANCH !== 'main' &&
  process.env.NEXT_PUBLIC_GIT_BRANCH !== 'prerelease';

const CODAT_PROD = process.env.NEXT_PUBLIC_CODAT_SERVER_URL_PROD;
const CODAT_STAGING = process.env.NEXT_PUBLIC_CODAT_SERVER_URL_STAGING;

export const CODAT_CREDENTIALS = 'codat-credentials';
export const VALIDIS_CREDENTIALS = 'validis-credentials';

export const AG_GRID_KEY = process.env.NEXT_PUBLIC_AG_GRID_KEY || '';

const config = {
  CI: process.env.CI,
  NODE_ENV: process.env.NODE_ENV,
  VERCEL_ENV: process.env.VERCEL_ENV,
  NEXT_PUBLIC_VERCEL_URL: process.env.NEXT_PUBLIC_VERCEL_URL,
  API_URL,
  ADMIN_API_URL,
  URL,
  CODAT_SERVER_URL: IS_LIVE_PROD ? CODAT_PROD : CODAT_STAGING,
  IS_TEST: process.env.NODE_ENV === 'test',
  IS_PROD: process.env.NODE_ENV === 'production',
  IS_LIVE_PROD,
  SHOW_DEBUG,
  WF_ENV,
  SENTRY_ENV:
    process.env.NEXT_PUBLIC_GIT_BRANCH === 'main'
      ? 'production'
      : process.env.NEXT_PUBLIC_GIT_BRANCH === 'prerelease'
      ? 'staging'
      : process.env.NEXT_PUBLIC_GIT_BRANCH === 'development'
      ? 'development'
      : 'local',
};

export default config;
