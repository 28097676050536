// uncomment below to engage why did you render
// import '../lib/wdyr.ts'
import '../styles/globals.css';
import 'react-toastify/dist/ReactToastify.css';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'flag-icons/css/flag-icons.min.css';
import '../styles/ag-grid.custom.scss';
import 'reactflow/dist/style.css';

import { LicenseManager } from 'ag-grid-enterprise';
import memoize from 'lodash/memoize';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { SessionProvider } from 'next-auth/react';
import { IntlError, NextIntlClientProvider } from 'next-intl';
import { RecoilRoot } from 'recoil';

import { AG_GRID_KEY } from '../config';
import useFullStory from '../hooks/useFullstory';

LicenseManager.setLicenseKey(AG_GRID_KEY);

// ignore in-browser next/js recoil warnings until its fixed.
const mutedConsole = memoize(console => ({
  ...console,
  warn: (...args: any) =>
    args[0].includes('Duplicate atom key') ? null : console.warn(...args),
}));
global.console = mutedConsole(global.console);

if (
  process.env.NODE_ENV !== 'production' &&
  process.env.NODE_ENV !== 'development'
) {
  require('../__mocks__/service-worker/index');
}

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  useFullStory();

  return (
    <>
      {/* @ts-ignore - UPGRADE */}
      <RecoilRoot>
        <SessionProvider session={pageProps.session}>
          <NextIntlClientProvider
            messages={pageProps.messages || {}}
            onError={(_: IntlError) => null}
            locale={router.locale || 'en'}
          >
            {/* @ts-ignore */}
            <Component {...pageProps} />
          </NextIntlClientProvider>
        </SessionProvider>
      </RecoilRoot>
    </>
  );
}

export default MyApp;
